import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { SecurityProvider } from 'security';
import { SettingsContext } from './contextAPI/settings';
import axios from 'axios';
import { SETTINGS } from 'urls';
import { loadJS } from 'utils/helper';
import { UserAuthRolesProvider } from 'contextAPI/userAuthRolesContext';
import { MapProvider } from 'contextAPI/mapContext';
import { LifeCycleProvider } from 'contextAPI/statusLifecycleContext';
import { ConfigurationProvider } from 'contextAPI/configurationContext';
import { SideNavProvider } from 'contextAPI/SideNavContext';
import { decrypt, generateNonce } from 'security/Hash';
import IdleMonitor from 'pages/ParticipantDataCollection/components/ApplicationSession/TimeoutComponent.react';

function bootStrapApplication() {
  let nonce = generateNonce();
  axios
    .get(SETTINGS, { params: { nonce: nonce } })
    .then((res) => JSON.parse(decrypt(res.data, nonce)))
    .then((settings) => initilizeApp(settings));
}

function initilizeApp(settings) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  //loading cookie script
  loadJS(
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    '2c0a40e5-b387-4755-b268-44a200578a02-test',
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <SettingsContext.Provider value={settings}>
          <ConfigurationProvider>
            <SecurityProvider>
              <UserAuthRolesProvider encodingEnabled>
                <SideNavProvider>
                  <MapProvider>
                    <LifeCycleProvider>
                      <App />
                      <IdleMonitor />
                    </LifeCycleProvider>
                  </MapProvider>
                </SideNavProvider>
              </UserAuthRolesProvider>
            </SecurityProvider>
          </ConfigurationProvider>
        </SettingsContext.Provider>
      </BrowserRouter>
    </React.StrictMode>,
  );
}

bootStrapApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
