import styled from 'styled-components';
import { SortColValues } from './Participants.content';
import {
  BLUE,
  COOL_GRAY,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  PAGE_BACKGROUND_COLOR,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
export const ParticipantButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 1rem;
  margin: 1rem 0 0rem 0;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.26rem;
  border-radius: 50%;
`;
export const handleArrowColor = (col, sortColumn) => {
  return {
    color: sortColumn === SortColValues[col] ? COOL_GRAY : '#ffffff',
    '&:hover': {
      color: COOL_GRAY,
    },
  };
};

export const backdropSx = { color: '#fff', zIndex: 999 };

export const EmailTextArea = styled.div`
  background: var(--primary-white, #ffffff);
  border-radius: 2px;
  border-style: solid;
  border-color: var(--grays-cool-gray-7-formunderlines, #97999b);
  border-width: 1px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;
export const EmailText = styled.div`
  color: var(--primary-jet-defaultbodycopy, #333333);
  text-align: left;
  font: 400 14px 'OpenSans-Regular', sans-serif;
  position: relative;
  align-self: stretch;
`;

export const EmailLink = styled.div`
  color: ${GLOBAL_BLUE};
  text-align: left;
  font: 600 14px 'OpenSans-SemiBold', sans-serif;
  text-decoration: underline;
  position: relative;
`;

export const EditableTextArea = styled.textarea`
  overflow: 'hidden';
  border: none;
  outline: none;
  width: 100%;
  resize: none;
`;

export const ReviewText = styled.p`
  color: ${DARK_CHARCOAL};
  text-align: left;
  position: relative;
  align-self: stretch;
  margin: 0 0 0.5rem 0;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin: ${({ margin }) => margin};
  font: 400 14px 'OpenSans-Regular', sans-serif;
`;
export const PdfDownloadButton = styled.button`
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  color: ${GLOBAL_BLUE};
  text-align: left;
  font: 400 14px 'OpenSans-Regular', sans-serif;
  position: relative;
`;

export const RadioBtnGroupSx = {
  '.MuiRadio-root': { padding: '0 9px' },
};

export const addBtnSx = {
  padding: '6px 8px',
  height: '1.75rem',
};

export const TextWrapper = styled.div`
  display: ${(props) => checkTernaryCondition(props.noFlex, '', 'flex')};
  justify-content: ${(props) => props.justifyContent};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${({ height }) => height};
  cursor: ${({ cursor }) => cursor};
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const breadcrumbSx = {
  '& .MuiBreadcrumbs-separator': {
    marginLeft: '-0.25rem',
    marginRight: '-0.25rem',
  },
  '& .MuiBreadcrumbs-li': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '25rem',
  },
};

export const reopenDialogTitleStyle = {
  padding: '1.5rem 0 0 1.5rem',
};

export const reopenDialogContentStyle = {
  maxWidth: 640,
  padding: '1.5rem',
};

export const reopenDialogActionStyle = {
  padding: '0 1.5rem 1.5rem 1.5rem',
};

export const DialogStyleSx = {
  '& .MuiBackdrop-root': {
    backgroundColor: 'unset',
    top: 50,
  },
};

export const ReviewPDFSx = {
  backgroundColor: PAGE_BACKGROUND_COLOR,
};

export const ParticipantListTabberStyle = { paddingTop: '10px' };

export const contactEmail = {
  fontFamily: 'Open Sans',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1.02125rem',
  letterSpacing: '0.0125rem',
  textAlign: 'left',
  color: COOL_GRAY,
};

export const activityStatusSx = {
  display: 'flex',
  flexDirection: 'column',
};

export const acreLabelSx = {
  color: COOL_GRAY,
  whiteSpace: 'pre',
};
export const addButtonVisibleStyle = {
  paddingRight: '0.5rem',
  marginLeft: 'auto',
};

export const filterIconSx = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.25rem',
};

export const TableWrapper = styled.div`
  background-color: ${WHITE};
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.375rem 0rem 0.375rem 0;
`;

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  color: ${BLUE};
`;

export const LinkItem = styled.span`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.192rem;
  text-align: left;
  text-decoration: underline;
`;

export const ContributorStatusWrapper = styled.div`
  //styleName: Body Copy - standard;
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.225rem;
  text-align: left;
  padding-left: 0.25rem;
`;

export const ParticipantNameCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
`;
export const participantContactSx = {
  width: '12rem',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const participantNameSx = {
  ...participantContactSx,
  color: DARK_CHARCOAL,
  fontSize: '0.875rem',
};

export const participantEmailSx = {
  ...participantContactSx,
  color: COOL_GRAY,
  fontSize: '0.75rem',
  fontWeight: 400,
};
export const SearchFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  alignitems: center;
  gap: 1rem;
`;

export const AccountStatusFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
  padding: 0rem 0.5rem 0.5rem 0rem;
`;

export const ActivityStatusWrapper = styled.div`
  display: flex;
  align-items: enter;
  width: 100%;
  justify-content: flex-end;
  gap: 0.25rem;
`;

export const ActivityStatusFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 55%;
  justify-content: flex-end;
  gap: 0.25rem;
`;

export const SummaryHeaderWrapper = styled.div`
  display: flex;
`;

export const SummaryViewHeaderWrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 45rem;
`;

export const Container = styled.div`
  width: 30.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.25rem 0;
`;

export const BoldText = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ListSx = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0 0 1.25rem;
  margin: 0;
`;
